import React, { useEffect } from 'react';

const Privacy = () => {
  useEffect(() => {
    // Load the iubenda script when the component mounts
    const loader = () => {
      const script = document.createElement("script");
      script.src = "https://cdn.iubenda.com/iubenda.js";
      script.async = true;
      document.body.appendChild(script);
    };

    if (window.addEventListener) {
      window.addEventListener("load", loader, false);
    } else if (window.attachEvent) {
      window.attachEvent("onload", loader);
    } else {
      window.onload = loader;
    }

    // Clean up function to remove the script when the component unmounts
    return () => {
      const scripts = document.getElementsByTagName("script");
      for (let i = 0; i < scripts.length; i++) {
        if (scripts[i].src === "https://cdn.iubenda.com/iubenda.js") {
          document.body.removeChild(scripts[i]);
        }
      }
    };
  }, []);

  return (
    <div className='lg:px-10 px-2 pt-8 mb-20 h-screen bg-white-400 flex justify-center flex-row container mx-auto w-screen'>
      <div className='mt-16 w-full md:px-0 px-4'>
        <div> 
          <h1 className='px-5 py-4 bg-[#FAFAFA] lg:text-3xl text-2xl text-center' style={{ fontFamily: 'GeneralSans-SemiBold' }}>Privacy Policy</h1>
        </div> 
        <div className='pb-4'>
          <a
            href="https://www.iubenda.com/privacy-policy/66708109"
            className="iubenda-white no-brand iub-body-embed iubenda-noiframe iubenda-embed iubenda-noiframe"
            title="Privacy Policy"> Privacy Policy</a>
        </div>
      </div>

    </div>
  );
};

export default Privacy;
